const baseURL = "https://portal.letsdiskus.com/";

//this is our services URL
const serviceAuthenticationURL = "/DAuth/";

const toDoListURL = "/DTDList/";

// ali work
const settingURL = "/DSettings/";

const settingDownloadURL = "/DSettingsDownload/";

// Huzeifa work for Meetings
const meetingURL = "/DMeetings/";

// huzeifa work
const getAdminURL = "/DAdmin/";

// DataRoom End Points;
const getDataRoomURL = "/DDataRoom/";

const getCommitteeURL = "/DCommittee/";

const getGroupsURL = "/DGroup/";
// Notes
const getNotesURL = "/DNotes/";

const getResolutionURL = "/DResolution/";

// socket url
const getSocketURL = ":9999";

//talk url
const talkURL = "/DTalk/";

const talkImageUrl = "/DTAT/";

// Calender
const calenderURL = "/DCalender/";

// Polls
const PollURL = "/DPoll/";

// Video URL
const videoURL = "/DVC/";

// Excel Report Download
const reportExcelUrl = "/DReportDownload/";

//File Download Data Room

const DataRoomFilesDownloads = "/DataRoomDownload/";

//Work Flow

const WorkFlowUrl = "/DWorkflow/";

const talkURLReport = "/DTAT/";

//this is our final api's
const authenticationApi = baseURL + serviceAuthenticationURL;

const toDoListApi = baseURL + toDoListURL;

const dataRoomApi = baseURL + getDataRoomURL;

// ali work
const settingApi = baseURL + settingURL;

const settingDownloadApi = baseURL + settingDownloadURL;

const meetingApi = baseURL + meetingURL;

const getTodoListAPI = baseURL + toDoListURL;

// get Socket connection
const getSocketConnection = baseURL + getSocketURL;

// get Admin
const getAdminURLs = baseURL + getAdminURL;

// get Notes
const getNotesApi = baseURL + getNotesURL;

const getResolutionApi = baseURL + getResolutionURL;

// get Talk Api
const talkApi = baseURL + talkURL;

const filesUrlTalk = baseURL + talkImageUrl;

const getGroupsApi = baseURL + getGroupsURL;

const getCommitteesApi = baseURL + getCommitteeURL;

// get Calender
const getCalender = baseURL + calenderURL;

const pollApi = baseURL + PollURL;

const videoApi = baseURL + videoURL;

// excel download Report
const reportDownload = baseURL + reportExcelUrl;

const DataRoomAllFilesDownloads = baseURL + DataRoomFilesDownloads;

const workflowApi = baseURL + WorkFlowUrl;
// excel download Report

const talkApiReport = baseURL + talkURLReport;

export {
  DataRoomAllFilesDownloads,
  reportDownload,
  authenticationApi,
  toDoListApi,
  settingApi,
  meetingApi,
  getTodoListAPI,
  getSocketConnection,
  getAdminURLs,
  getNotesApi,
  talkApi,
  getGroupsApi,
  getCommitteesApi,
  getResolutionApi,
  dataRoomApi,
  getCalender,
  pollApi,
  filesUrlTalk,
  videoApi,
  settingDownloadApi,
  workflowApi,
  talkApiReport
};